body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  span,
  div {
    font-feature-settings: 'liga' off;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

.share-facebook_btn {
  width: 100% !important;
  padding: 12px 16px !important;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #141823 !important;
  border: 1px solid #3D414D !important;
  border-radius: 12px !important;
  svg {
    margin-right: 25px;
  }
  &:hover {
    border-color: #FF8400 !important;
  }
}
