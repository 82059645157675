@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: 'woff') {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == 'woff' {
      src: url('#{$file-path}.#{$format}') format($format);
    } @else {
      src: url('#{$file-path}.#{$format}') format('truetype');
    }
  }
}

@include font-face('Inter', '/assets/fonts/Inter-Regular', 400, normal, 'ttf');
@include font-face('Inter', '/assets/fonts/Inter-Medium', 500, medium, 'ttf');
@include font-face('Inter', '/assets/fonts/Inter-SemiBold', 600, medium, 'ttf');
@include font-face('Future-Earth', '/assets/fonts/Future-Earth-Regular', 400, normal, 'ttf');
@include font-face('Xolonium', '/assets/fonts/Xolonium-Regular', 400, normal, 'ttf');
