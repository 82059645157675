.Toastify__toast {
  font-family: 'Inter', sans-serif;
  min-height: 56px !important;
  border: 1px solid !important;
  border-radius: 12px !important;
  font-weight: 400 !important;
  background: #141823;
  padding: 15px 17px 18px 18px;
  @media (max-width: 480px) {
    margin: 0 10px;
  }

  &-container {
    max-width: 454px;
    width: 100%;
  }
  svg {
    width: 20px !important;
  }

  &-body {
    padding: 0 !important;
    align-items: flex-start;
  }
  &-icon {
    width: fit-content !important;
    margin-top: 5px;
    margin-right: 18px !important;
  }

  &--success {
    border-color: #00AA2D !important;
    color: #00AA2D !important;
    svg {
      fill: #00AA2D !important;
    }
    .Toastify__close-button {
      svg {
        fill: #00AA2D !important;
      }
    }
  }
  &--info {
    border-color: #3D414D !important;
    color: #798094 !important;
    svg {
      fill: #798094 !important;
    }
    .Toastify__close-button {
      svg {
        fill: #798094 !important;
      }
    }
  }
  &--warning {
    border-color: #F2C94C !important;
    color: #F2C94C !important;
    svg {
      fill: #F2C94C !important;
    }
    .Toastify__close-button {
      svg {
        fill: #F2C94C !important;
      }
    }
  }
  &--error {
    border-color: #FF4040 !important;
    color: #FF4040 !important;
    svg {
      fill: #FF4040 !important;
    }
    .Toastify__close-button {
      svg {
        fill: #FF4040 !important;
      }
    }
  }
  &--default {
    border-color: rgba(94, 153, 245, 1) !important;
    color: rgba(94, 153, 245, 1) !important;
  }
}

.Toastify__close-button {
  opacity: 1 !important;
  margin-top: 7px;
  margin-left: 49px;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
